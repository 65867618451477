import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "home" */ '../views/AboutView.vue')
  },
  {
    path: '/plasmid',
    name: 'plasmid',
    component: () => import(/* webpackChunkName: "home" */ '..//views/PlasmidView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "home" */ '..//views/ContactView.vue')
  },
  {
    path: '/mRNA',
    name: 'mRNA',
    component: () => import(/* webpackChunkName: "home" */ '..//views/MranView.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

export default router
